import React, { ReactNode } from "react";
import { cn } from "../../../utils/twMerge";

interface Props {
  tag:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "p"
    | "strong"
    | "span"
    | "small";
  className?: string;
  children?: ReactNode;
  id?: string;
}

const typographyClasses = {
  h1: "wb-text-[2em] wb-font-bold wb-my-[0.67em] wb-leading-[1.2em]",
  h2: "wb-text-[1.5em] wb-font-bold wb-my-[0.67em] wb-leading-[1.2em]",
  h3: "wb-text-[1.17em] wb-font-bold wb-my-[0.67em] wb-leading-[1.2em]",
  h4: "wb-text-[1em] wb-font-bold wb-my-[0.67em] wb-leading-[1.2em]",
  h5: "wb-text-[0.83] wb-font-bold wb-my-[0.67em] wb-leading-[1.2em]",
  h6: "wb-text-[0.67] wb-font-bold wb-my-[0.67em] wb-leading-[1.2em]",
  p: "wb-block wb-my-[1em]",
  span: "wb-text-inherit",
  strong: "wb-font-bold",
  small: "wb-text-xs",
};

export const Typography: React.FC<Props> = ({
  tag,
  id,
  className = "",
  children,
}) => {
  const Tag = tag;

  return (
    <Tag id={id} className={cn(typographyClasses[tag], className)}>
      {children}
    </Tag>
  );
};
