const appConfig = {
  appBaseUrl: process.env.NEXT_PUBLIC_BASE_URL ?? "",
  sitemapUrl: process.env.NEXT_PUBLIC_SITEMAP_URL ?? "",
  recaptchaSiteKey: process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY ?? "",
  googleMapApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY ?? "",
  apiBaseUrl: process.env.NEXT_PUBLIC_API_BASE_URL ?? "",
  clubBaseUrl: process.env.NEXT_PUBLIC_CLUB_BASE_URL ?? "",
  nodeEnv: process.env.NEXT_PUBLIC_NODE_ENV ?? "",
};

export default appConfig;
