"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { ReactNode } from "react";

export interface ClientLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
}

export const ClientLink = ({
  children,
  href,
  className = "",
}: ClientLinkProps) => {
  const pathname = usePathname();

  return (
    <Link
      className={`${pathname === href ? "active" : ""} ${className}`}
      href={href}
    >
      {children}
    </Link>
  );
};
