import(/* webpackMode: "eager" */ "/app/apps/book-the-play/modules/components/BecomeAPartner/FormHOC.tsx");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/modules/components/BecomeAPartner/PartnerForm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/modules/components/Faq/FrequentlyAskQuestionAccordion.tsx");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/modules/components/Header.tsx");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/modules/components/Modal.tsx");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/modules/components/Overview/OverviewSwiper.tsx");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/appStore.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/instagram.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/linkedin.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/mailIcon.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/phoneIcon.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/platStore.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/svg/youTube.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/venueFeatures/1unified.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/venueFeatures/2effortless.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/venueFeatures/3increase.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/venueFeatures/4courtscheduling.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/venueFeatures/5track.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/img/venueFeatures/6realtime.svg");
import(/* webpackMode: "eager" */ "/app/apps/book-the-play/public/logo/color.svg");
import(/* webpackMode: "eager" */ "/app/libs/src/components/Transition/Transition.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js")