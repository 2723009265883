"use client";

import React from "react";
import { forwardRef, ForwardedRef, ReactNode } from "react";
import { motion, MotionProps } from "framer-motion";
import {
  DEFAULT_ANIMATION,
  DEFAULT_TRANSITION,
  DEFAULT_VIEWPORT,
} from "@libs/utils/animation";

interface TransitionProps {
  children?: ReactNode;
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}

const TransitionComponent: React.ForwardRefRenderFunction<
  HTMLDivElement,
  TransitionProps & MotionProps
> = (
  { children, as = "div", transition, ...rest },
  ref: ForwardedRef<HTMLDivElement>,
) => {
  const Component = motion(as);

  return (
    <Component
      ref={ref}
      variants={DEFAULT_ANIMATION}
      initial="initial"
      whileInView="final"
      whileHover="hover"
      exit="initial"
      transition={{ ...DEFAULT_TRANSITION, ...transition }}
      viewport={DEFAULT_VIEWPORT}
      {...rest}
    >
      {children}
    </Component>
  );
};

export const Transition = forwardRef(TransitionComponent);
